/**
 * bot分享
 */

import type {
  BotChatShareConfig,
  BotEmbedShareConfig,
  BotHistoryShareConfig,
  RespObject,
} from './model';
import { withParam, postData, getData } from '@/utils/fetch';

/**
 * 获取分享Bot配置
 */
export const takeChatConfig = () => {
  return getData<RespObject<BotChatShareConfig>>('/api/share/bot/share/chat/config/take', {});
};

/**
 * 校验分享Bot密码
 * @param password password
 */
export const validatePassword = (password: string) => {
  return postData<RespObject<string>>(
    withParam('/api/share/bot/share/chat/config/validate', { password }),
  );
};

/**
 * 获取嵌入Bot配置
 */
export const takeEmbedConfig = () => {
  return getData<RespObject<BotEmbedShareConfig>>('/api/share/bot/share/embed/config/take', {});
};

/**
 * 获取分享聊天记录配置
 */
export const takeHistoryConfig = () => {
  return getData<RespObject<BotHistoryShareConfig>>('/api/share/bot/share/history/config/take', {});
};
