export default {
  'app.copy': 'Copiar',
  'app.myBot.chat.clean.tips':
    '¡El historial de chat ha sido borrado, empezar de nuevo también es una buena opción!',
  'app.myBot.chat.context.unLink': 'Lo siento, el enlace ha caducado y ya no es válido.',
  'app.myBot.chat.clean.lookMore': 'Ver más',
  'app.myBot.chat.timeout.loading': 'Cargando',
  'app.myBot.chat.timeout.placeholder':
    'Empieza la conversación (Enter para enviar, Ctrl + Enter para nueva línea)',
  'app.myBot.chat.timeout.placeholderMobile': 'Empieza la conversación',
  'app.myBot.chat.stop': 'Detener la respuesta',
  'app.myBot.chat.goConversation': 'Ir a la conversación',
  'app.myBot.chat.speech.recognition.progress': 'Grabando...',
  'app.myBot.chat.speech.recognition.error':
    'El reconocimiento de voz falló, por favor intente de nuevo o use texto.',
  'app.myBot.chat.speech.converting.text': 'Reconociendo...',
  'app.myBot.chat.record.error':
    'El micrófono ha sido deshabilitado, por favor, verifique la configuración del sitio en el navegador',
  'app.myBot.chat.question.title': 'Intenta preguntar',
  'app.plugin.PluginCallSuccess': 'Llamada al plugin realizada con éxito',
  'app.plugin.PluginCallWait': 'Esperando llamada al plugin',
  'app.plugin.PluginCallFail': 'Error en la llamada al plugin',
  'app.home.embed.deny': 'El widget debe estar incrustado en un sitio web para su uso',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.error':
    'Error de carga',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading':
    'Cargando',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading1':
    'Solo se pueden cargar imágenes en formato jpg, png, gif, bmp, webp',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading2':
    'El tamaño de la imagen no puede exceder los 20 MB',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading3':
    'Se pueden cargar hasta {imageLimit} imágenes',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading4':
    'La versión del modelo seleccionada por este BOT no soporta la entrada de imágenes',
  'app.share.pwdTip': 'Por favor, introduzca la contraseña',
  'app.share.pwdBtn': 'Iniciar conversación',
};
