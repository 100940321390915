import { getUrlParams } from '@/utils/randomString';

type ChatType = 'chat' | 'history' | 'embed' | 'widget';

export function getUrlQuery(query: string) {
  const urlStr = location.href.split('?')[1];
  if (!urlStr) return undefined;
  const obj: any = {};
  const paramsArr = urlStr.split('&');
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    const arr = paramsArr[i].split('=');
    obj[arr[0]] = arr[1];
  }
  return obj[query];
}

export default (): ChatType => {
  const type = getUrlParams().toString();
  if (type.startsWith('c') || type.startsWith('ss')) {
    return 'chat';
  } else if (type.startsWith('h')) {
    return 'history';
  } else if (type.startsWith('e') || type.startsWith('se')) {
    return 'embed'; //外嵌iframe
  } else if (type.startsWith('w')) {
    return 'widget'; //外嵌小部件
  } else {
    return 'chat';
  }
};

export function getChatTicket() {
  return location.pathname.split('/')[2];
}
