export default {
  'app.copy': '复制',
  'app.myBot.chat.clean.tips': '已清空聊天记录，重新开始也是一种好的选择！',
  'app.myBot.chat.context.unLink': '非常抱歉，该链接已到期失效！',
  'app.myBot.chat.clean.lookMore': '查看更多',
  'app.myBot.chat.timeout.loading': '加载中',
  'app.myBot.chat.timeout.placeholder': '开始对话吧（Enter发送，Ctrl + Enter换行）',
  'app.myBot.chat.timeout.placeholderMobile': '开始对话吧',
  'app.myBot.chat.stop': '停止回答',
  'app.myBot.chat.goConversation': '去对话',
  'app.myBot.chat.speech.recognition.progress': '录音中…',
  'app.myBot.chat.speech.recognition.error': '语音识别失败，请重试或者使用文字！',
  'app.myBot.chat.speech.converting.text': '识别中…',
  'app.myBot.chat.record.error': '麦克风已被禁用请检查浏览器站点设置',
  'app.myBot.chat.question.title': '试着问问',
  'app.plugin.PluginCallSuccess': '插件调用成功',
  'app.plugin.PluginCallWait': '插件调用等待',
  'app.plugin.PluginCallFail': '插件调用失败',
  'app.home.embed.deny': '小部件应嵌入到某个网站中使用',
  //上传错误
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.error':
    '上传错误',
  //上传中
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading':
    '上传中',
  //只能上传jpg、png、gif、bmp、webp格式的图片
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading1':
    '只能上传jpg、png、gif、bmp、webp格式的图片',
  //图片大小不能超过20MB
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading2':
    '图片大小不能超过20MB',
  //最多支持上传imageLimitge张图片
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading3':
    '最多支持上传{imageLimit}张图片',
  //该BOT所选择的模型版本 不支持图片输入
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading4':
    '该BOT所选择的模型版本 不支持图片输入',
  'app.share.pwdTip': '请输入密码',
  'app.share.pwdBtn': '开始对话',
};
