import { message } from 'ant-design-vue';
import errorInterceptor from './error-interceptor';
import { getStorage } from '@/utils/storage';
import { getUrlParams } from '@/utils/randomString';
import { getAccountId } from '@/utils/accountId';
import { getDefaultLocale } from '@/i18n/index';
import { getToken, deleToken } from '@/utils/common';
// const accountId = getAccountId();
let ticket = getUrlParams();
export function setTicket(tickerStr: string) {
  ticket = tickerStr;
}
export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';
export type QueryBase<DATA> = {
  data: DATA;
  code: number;
  message: string;
};

let onceAutoTip: null | boolean = null;

export function setOnceAutoTip(autoTip: boolean) {
  onceAutoTip = autoTip;
}

export default <T>(
  url: string,
  method: MethodType,
  body?: Record<string, string> | null,
  headers?: HeadersInit,
  autoTip = true,
  onDownloadProgress?: (progress: number, value?: any) => void,
  timeout = 60000,
): Promise<T> => {
  const controller = new AbortController();
  const id = setTimeout(() => {
    return controller.abort();
  }, timeout);
  const _url = url.startsWith('http') ? url : `${process.env.API_BASE_URL ?? ''}${url}`;
  return new Promise((resolve, reject) => {
    if (onceAutoTip != null) {
      autoTip = onceAutoTip;
      onceAutoTip = null;
    }
    fetch(
      _url,
      Object.assign(
        {
          signal: controller.signal,
          method,
          headers: Object.assign(
            {
              // 预制头
              Locale: getDefaultLocale(),
              Authorization: 'Basic ' + btoa(getAccountId() + ':' + ticket + getToken(ticket)),
              'Content-Type': 'application/json',
            },
            headers,
          ),
        },
        body && { body: JSON.stringify(body) },
      ),
    )
      .then(response => response.json())
      .then((res: any) => {
        if (res.code === 200263) {
          if (!localStorage.getItem('pwdToken' + ticket)) {
            return res;
          }
          deleToken();
        }
        // 拦截业务层报错
        if (res.code) errorInterceptor(res, autoTip);
        return res;
      })
      .then(resolve)
      .catch(err => {
        if (autoTip) message.error(err.message);
        reject(err);
      })
      .finally(() => {
        clearTimeout(id);
      });
  });
};
