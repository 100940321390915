export default {
  'app.copy': 'コピー',
  'app.myBot.chat.clean.tips': 'チャット履歴を消去しました。新しい始まりもいいですね!',
  'app.myBot.chat.context.unLink': '申し訳ありませんが、そのリンクは期限切れで無効になっています。',
  'app.myBot.chat.clean.lookMore': 'さらに見る',
  'app.myBot.chat.timeout.loading': '読み込み中',
  'app.myBot.chat.timeout.placeholder': '対話を始めましょう(Enterで送信、Ctrl + Enterで改行)',
  'app.myBot.chat.timeout.placeholderMobile': '対話を始めましょう',
  'app.myBot.chat.stop': '回答を停止',
  'app.myBot.chat.goConversation': '対話に行く',
  'app.myBot.chat.speech.recognition.progress': '録音中...',
  'app.myBot.chat.speech.recognition.error':
    '音声認識に失敗しました。再度試すか、文字をお使いください。',
  'app.myBot.chat.speech.converting.text': '認識中...',
  'app.myBot.chat.record.error':
    'マイクが無効になっています。ブラウザのサイト設定を確認してください。',
  'app.plugin.PluginCallSuccess': 'プラグインの呼び出しに成功しました',
  'app.plugin.PluginCallWait': 'プラグインの呼び出し待機中',
  'app.plugin.PluginCallFail': 'プラグインの呼び出しに失敗しました',
  'app.myBot.chat.question.title': '試してみましょう',
  'app.home.embed.deny': 'ウィジェットはWebサイトに組み込んで使用する必要があります',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.error':
    'アップロードエラー (Appurōdo erā)',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading':
    'アップロード中',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading1':
    'jpg、png、gif、bmp、webp形式の画像のみをアップロードできます',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading2':
    '画像サイズは20MBを超えることはできません',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading3':
    '最大{imageLimit}枚の画像をアップロードできます',
  'app.space.bot.create.form.multimodalConfigurationRef.voice.output.text.language.loading4':
    '選択したボットのモデルバージョンは画像入力をサポートしていません',
  'app.share.pwdTip': 'パスワードを入力してください',
  'app.share.pwdBtn': '会話を開始',
};
