import { useRequest } from '@/hooks';
import { takeChatConfig } from '@/api/gen/bot-share-controller';
import { ref } from 'vue';
import { BotChatShareConfig } from '@/api/gen/model';
export interface ShareConfigInfo extends BotChatShareConfig {
  errorMsg?: string;
}
const shareInfo = ref<ShareConfigInfo | undefined>(undefined);
import { setStorage } from '@/utils/storage';

export default () => {
  const update = (callback?: (data: BotChatShareConfig | undefined) => void) => {
    const { loading } = useRequest(takeChatConfig, {
      onSuccess: ({ data }) => {
        shareInfo.value = data;
        setStorage({
          key: 'i18n_redirected',
          value: data.locale,
        });
        callback && callback(data);
      },
      onError: (res: any) => {
        shareInfo.value = { errorMsg: res.message, pwdRequired: false };
        callback && callback(undefined);
      },
    });
    return { loading };
  };
  return { shareInfo, update };
};
