import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/s/c:id',
    name: 'share-chat',
    component: () => import(/* webpackChunkName: "share-chat" */ '@/views/share/chat.vue'),
  },
  {
    path: '/s/h:id',
    name: 'share-history',
    component: () => import(/* webpackChunkName: "share-history" */ '@/views/share/history.vue'),
  },
  {
    path: '/widget/e:id/chat.html',
    name: 'share-embed',
    component: () => import(/* webpackChunkName: "share-embed" */ '@/views/share/embed.vue'),
  },
  {
    path: '/widget/w:id/chat.html',
    name: 'share-widget',
    component: () => import(/* webpackChunkName: "share-widget" */ '@/views/share/widget.vue'),
  },
  {
    path: '/ai-search/:id/chat.html',
    name: 'ai-search-iframe',
    component: () => import(/* webpackChunkName: "ai-search" */ '@/views/AiSearch/index.vue'),
  },
  {
    path: '/ai-search/:id',
    name: 'ai-search',
    component: () => import(/* webpackChunkName: "ai-search" */ '@/views/AiSearch/index.vue'),
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorPage.vue'),
  },
  {
    path: '/s/auth',
    name: 'auth-page',
    component: () => import(/* webpackChunkName: "error" */ '@/views/PwdPage.vue'),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
