import { getUrlParams } from '@/utils/randomString';
import { BotChatShareConfig, BotEmbedShareConfig } from '@/api/gen/model';
export function hasPwd(data: BotChatShareConfig | BotEmbedShareConfig) {
  let hasToken = true;
  if (data.pwdRequired) {
    const ticket = getUrlParams() as string;
    const token = getToken(ticket);
    if (!token && ticket) {
      sessionStorage.setItem(
        'sharePwdInfo',
        JSON.stringify({
          ticket,
          url: location.href,
          data,
        }),
      );
      hasToken = false;
      location.href = '/s/auth';
    }
  }
  return hasToken;
}
export function getShareInfo() {
  return (JSON.parse(sessionStorage.getItem('sharePwdInfo') || '') || {
    url: '',
    data: {},
    ticket: '',
  }) as {
    ticket: string;
    url: string;
    data: BotChatShareConfig | BotEmbedShareConfig;
  };
}
export function setToken(ticket: string, token: string) {
  localStorage.setItem('pwdToken' + ticket, token);
}
export function getToken(ticket?: string | boolean) {
  ticket = ticket || getUrlParams();
  if (!ticket) {
    return '';
  }
  let token = localStorage.getItem('pwdToken' + ticket) || '';
  token = token ? '-' + token : '';
  return token;
}
export function deleToken() {
  const ticket = getUrlParams();
  if (ticket) {
    localStorage.removeItem('pwdToken' + ticket);
    location.reload();
  }
}
